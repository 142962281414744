import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import asyncComponent from './utility/AsyncComponent'
import Navigation from './renderonly/Navigation';
import AboutPage from './renderonly/About';
import CaseStudiesPage from './renderonly/CaseStudies';
import AboutCookies from './renderonly/AboutCookies';
import SignInPage from './renderonly/SignIn';
import OpportunitiesPage from './renderonly/Opportunities';
import PageNotFound from './renderonly/PageNotFound';

import { firebase } from '../firebase';
import { functions } from '../firebase/firebase';
import { Loader, Dimmer, Container, Segment, Grid, Image } from 'semantic-ui-react';
import RawLogo from '../res/by-raw-fin-fin.png';

const AsyncProfilePage = asyncComponent(() => import('./editors/Profile'));
const AsyncSingleOpp = asyncComponent(() => import('./editors/SingleOpp'));
const AsyncCreateUserPage = asyncComponent(() => import('./admin/CreateUser'));
const AsyncManageUsersPage = asyncComponent(() => import('./admin/ManageUsers'));
const AsyncEditUser = asyncComponent(() => import('./admin/EditUser'));
const AsyncAnalyticsPage = asyncComponent(() => import('./renderonly/Analytics'));
const AsyncHelpPage = asyncComponent(() => import('./renderonly/Help'));
const AsyncConfigPage = asyncComponent(() => import('./utility/Config'));
const AsyncPartnersPage = asyncComponent(() => import('./renderonly/Partners'));
const AsyncSinglePartner = asyncComponent(() => import('./renderonly/SinglePartner'));
const AsyncSinglePartnerPublic = asyncComponent(() => import('./renderonly/SinglePartnerPublic'));
const AsyncSingleVolunteer = asyncComponent(() => import('./renderonly/SingleVolunteer'));
const AsyncVolunteersPage = asyncComponent(() => import('./renderonly/Volunteers'));
const AsyncNewOpportunity = asyncComponent(() => import('./editors/NewOpportunity'));

// Per-role visit counter - this is anonymised, just an increment of an int for each day (midnight timestamp).
// We only count a single daily visit regardless of how many hits or reloads are done per day.
// We do this by storing a 'lastHitDate' timestamp in localStorage - if it's today, we don't count again.
// We also don't update stats if build is running on localhost.
const updateHitCount = (countType) => {
  // Allowed types: apply, suggest, user_edit, partner_edit, user_visit, partner_visit, admin_visit
  let tdate = new Date().setHours(0,0,0,0);
  if(window.location.host.indexOf('localhost') < 0 && (localStorage.getItem('regionID') >= 0)) {
    if(!localStorage.getItem('lastHitDate') || localStorage.getItem('lastHitDate') < tdate) {

        var countUsage = functions.httpsCallable('usageCounter');
        countUsage({type: countType, regionID: localStorage.getItem('regionID')}).then((result) => {
          console.log(result.data);
          localStorage.setItem('lastHitDate', tdate);
          return result;
        }, (error) => {
          console.log(error);
        })

    }
  }

}

class App extends Component {
  constructor(props) {
    super(props);

    // kill all console.log output in production
    if(process.env.NODE_ENV == 'production')
    {
      console.log = function () {};
    }

    let authUser = null;
    let hasLocalStorageUser = false;
    let userRole = 'none';
    let isAdmin = false;
    let isSuperuser = false;
    let isRegionSuperuser = false;
    let isPartner = false;

    // regionID is a cached value for soft checks, ie to filter read-only results by region. Hard checks,
    // e.g. when admins and regionadmins want to perform write operations, should be done against the database
    // or server-side
    let regionID = -1;

    // set auth from localstorage - this fixes route problems on reload, but doesn't
    // compromise security (server reads/writes are still subject to user auth/role status);
    // If an admin was previously logged in and a lower-privileged user then logged in,
    // they'd have front-end admin status until the Firebase auth object was received, which
    // would overwrite them (and even in those few milliseconds they wouldn't be able to read/write
    // beyond the database rules)
    for (let key in localStorage) {
      if(key.indexOf('fbUID') > -1) {
        hasLocalStorageUser = true;
        break;
      }
    }

    for (let key in localStorage) {
      if(key.indexOf('smIsAdmin') > -1) {
        isAdmin = true;
        break;
      }
    }

    for (let key in localStorage) {
      if(key.indexOf('smIsSuperuser') > -1) {
        isSuperuser = true;
        break;
      }
    }

    for (let key in localStorage) {
      if(key.indexOf('smIsRegionSuperuser') > -1) {
        isRegionSuperuser = true;
        break;
      }
    }

    for (let key in localStorage) {
      if(key.indexOf('smIsPartner') > -1) {
        isPartner = true;
        break;
      }
    }

    for (let key in localStorage) {
      if(key.indexOf('regionID') > -1) {
        regionID = localStorage.getItem(key);
        break;
      }
    }

    this.state = {
      authUser,
      hasLocalStorageUser,
      feedOpps: {},
      userRole,
      regionID,
      isAdmin,
      isSuperuser,
      isRegionSuperuser,
      isPartner,
      loaded: true,
      debugMode: false, // SET TO FALSE FOR PRODUCTION!
      globalSkills: [''],
      globalSectors: [''],
      partners: [{key: 0, value: ' ', text: ' '}],
      google: {
        apiKey: 'AIzaSyDSEmkHpwkyUQwZVsK-4BPbyIbuW3Q3K9E'
      }
    };

    this.authFlagHandler = this.authFlagHandler.bind(this);    
  };

  loaderHandler(loaderBool) {
    this.setState({loaded: loaderBool});
    // If we decide to use this, see: https://stackoverflow.com/questions/45610582/set-state-with-props-from-parent-component
    // ie: pass loaderHandler={this.loaderHandler} into all Routed components below,
    // then flip the switch in each component with this.props.loaderHandler(true) etc
  }

  authFlagHandler() {
    this.setState({hasLocalStorageUser: true});
  }

  logoutCleanup = ()  => {
    this.setState({
      authUser: null,
      hasLocalStorageUser:false,
      feedOpps: {},
      userRole: "none",
      isAdmin: false,
      isSuperuser: false,
      isRegionSuperuser: false,
      isPartner: false,
      loaded: true,
      debugMode: false, // SET TO FALSE FOR PRODUCTION!
      globalSkills: [''],
      globalSectors: [''],
      partners: [{key: 0, value: ' ', text: ' '}],
      google: {
        apiKey: 'AIzaSyDSEmkHpwkyUQwZVsK-4BPbyIbuW3Q3K9E'
        }
      })
    //console.log("logoutCleanup has been called");
  }

  componentDidMount() {

    firebase.auth.onAuthStateChanged(authUser => {
      if(authUser) {
        this.setState({ authUser, hasLocalStorageUser: true });
        localStorage.setItem('fbUID', authUser.uid.toString());

        // check dbase to see if user role flag needs to be elevated

        const roleRef = firebase.database.ref('/users/' + this.state.authUser.uid);

        roleRef.once('value')
          .then((dataSnapshot) => {
              localStorage.setItem('email', dataSnapshot.val().email);

              // Which should be the canonical source of regionID? Probably this.state, passed to props for other components...
              localStorage.setItem('regionID', dataSnapshot.val().regionID);
              this.state.regionID = dataSnapshot.val().regionID;
              
              // check admins table to see if we're an admin
              firebase.database.ref('/admins/' + this.state.authUser.uid).once('value', (snapshot) => {
                if(snapshot.exists() && snapshot.val().isAdmin) {

                  this.setState({ userRole: 'admin', isAdmin: true});
                  localStorage.setItem('smIsAdmin', true);
                  localStorage.removeItem('currentDeepSearchTerm');
                  console.log("user is admin")

                  // Count an Admin visit
                  updateHitCount('admin_visit');

                  // Admin, so set this custom claim for image upload purposes
                  var setStorageClaim = functions.httpsCallable('setCustomClaimsToken');


                  setStorageClaim({userID: this.state.authUser.uid}).then((result) => {
                    console.log(result.data);
                  }, (error) => {
                    console.log(error);
                  })

                  // If admin, get a list of partners for assigning Opps
                  firebase.database.ref('/users').once('value', (snapshot) => {
                    var tmpArr = [];
                    var iter = 0;
                    snapshot.forEach((user) => {
                      var tmpUser = user.val();
                      var tmpOpt = {};
                      if(tmpUser.isPartner) {
                        tmpOpt.key = iter;
                        tmpOpt.value = user.key;
                        tmpOpt.text = tmpUser.name;
                        tmpArr.push(tmpOpt);
                      }
                      iter++;
                    })
                    this.setState({partners: tmpArr});
                  })

                  // Also check to see if this admin is a regionsuperuser
                  firebase.database.ref('/regionsuperusers/' + this.state.authUser.uid).once('value', (snapshot) => {
                    if(snapshot.exists() && snapshot.val().isRegionSuperuser && (snapshot.val().regionID == this.state.regionID)) {
                      console.log("user is regionsuperuser");
                      this.setState({ isRegionSuperuser: true});
                      localStorage.setItem('smIsRegionSuperuser', true);
                    }
                  })

                  // Also check to see if this admin is a global superuser
                  firebase.database.ref('/superusers/' + this.state.authUser.uid).once('value', (snapshot) => {
                    if(snapshot.exists() && snapshot.val().isSuperuser) {
                      console.log("user is superuser");
                      // And if they're a global superuser, automatically give them regionsuperuser privileges as well
                      this.setState({ isSuperuser: true, isRegionSuperuser: true});
                      localStorage.setItem('smIsSuperuser', true);
                      localStorage.setItem('smIsRegionSuperuser', true);
                    }
                  })

                } else if(dataSnapshot.val().isPartner) {
                  console.log("user is partner");
                  this.setState({ userRole: 'partner', isPartner: true});
                  localStorage.setItem('smIsPartner', true);
                  localStorage.setItem('partnerName', dataSnapshot.val().name)
                  updateHitCount('partner_visit');
                } else {
                  console.log("user is user");
                  this.setState({ userRole: 'user'});
                  updateHitCount('user_visit');
                }

              })


              // If logged in fine, get globals:
              firebase.database.ref('/globals').once('value').then((snapshot) => {
                let allGlobals = snapshot.val();
                let mode = allGlobals.config.debugMode;
                var tmpSkills = Object.values(allGlobals.skills);
                var tmpSectors = Object.values(allGlobals.sectors);

                this.setState({
                  debugMode: mode,
                  globalSkills: tmpSkills,
                  globalSectors: tmpSectors,
                  loaded: true
                })
              }).catch((error) => {
                console.log("Error: ", error);
              })

           }).catch(function(error) {
             console.log("Error reading users: ", error);
           });

        // Also make sure skills and search filters are in localstorage.
        // This should listen for updates, e.g. when Profile is edited.
        // Remember to split by ',' when digging this out!
        firebase.database.ref('/users/' + this.state.authUser.uid + '/skills').on('value', snapshot => {
          if(snapshot.exists()) {
            localStorage.setItem('skillsFilters', snapshot.val());
          } else {
            localStorage.setItem('skillsFilters', '');
          }
        })

        // Disabled for now. Could easily be added in future.
        /*
        firebase.database.ref('/usersPrivate/' + this.state.authUser.uid).on('value', (snapshot) => {
          var tmp = snapshot.val();
          localStorage.setItem('searchFilters', tmp.searchFilters);
        })
        */

      } else {
        localStorage.removeItem('fbUID');
        localStorage.removeItem('smIsAdmin');
        localStorage.removeItem('smIsSuperuser');
        localStorage.removeItem('smIsRegionSuperuser');
        localStorage.removeItem('smIsPartner');
        localStorage.removeItem('partnerName');
        localStorage.removeItem('skillsFilters');
        localStorage.removeItem('searchFilters');
        localStorage.removeItem('lastHitDate');
        localStorage.removeItem('email');
        localStorage.removeItem('currentDeepSearchTerm');
        this.setState({
          hasLocalStorageUser: false,
          authUser: null,
          userRole: 'none',
          isAdmin: false,
          isPartner: false,
          loaded: true
        });
      }

    });

  }



  render() {

    return (



          !this.state.loaded ? (
            <Dimmer inverted active>
              <Loader size='huge' inverted content='Loading' />
            </Dimmer>
          ) : (

            <Router>
              <div style={{ display:"flex", minHeight:"100vh", flexDirection:"column" }}>
                <Navigation 
                  onLogOut={this.logoutCleanup} 
                  authUser={this.state.authUser} 
                  userRole={this.state.userRole} 
                  isAdmin={this.state.isAdmin} 
                  isSuperuser={this.state.isSuperuser} 
                  isRegionSuperuser={this.state.isRegionSuperuser} 
                  isPartner={this.state.isPartner} 
                  regionID={this.state.regionID} 
                />


                <Switch>

                  <Route
                    exact path='/'
                    // This'll probably be the public Skillsmatch landing page, with a signin button.
                    // Maybe combine it with the sign-in page.
                    /*  IE11 fix, 2019-06-17: flexBasis: 'auto' (fixes floating footer issue)  */
                    // Now main landing stuff is at skillsmatch.me - reroute this to sign-in (2021-11-16)
                    //render={() => <div style={{flex: 1, flexBasis: 'auto'}}><LandingPage authStatus={this.state.authUser} /></div>}
                    render={()=> <Redirect to="/signin" />}
                  />

                  <Route
                    exact path='/about'
                    // This'll probably be the public Skillsmatch landing page, with a signin button.
                    // Maybe combine it with the sign-in page.
                    render={() => <div style={{flex: 1, flexBasis: 'auto'}}><AboutPage authStatus={this.state.authUser} /></div>}
                  />

                  <Route
                    exact path='/case-studies'
                    // This'll probably be the public Skillsmatch landing page, with a signin button.
                    // Maybe combine it with the sign-in page.
                    render={() => <div style={{flex: 1, flexBasis: 'auto'}}><CaseStudiesPage authStatus={this.state.authUser} /></div>}
                  />

                  <Route
                    exact path='/about-cookies'
                    // This'll probably be the public Skillsmatch landing page, with a signin button.
                    // Maybe combine it with the sign-in page.
                    render={() => <div style={{flex: 1, flexBasis: 'auto'}}><AboutCookies authStatus={this.state.authUser} /></div>}
                  />

                  <Route
                    exact path="/partners"
                    // Redirect to sign-in page if user's not authed
                    // This is breaking page refreshes, as it always sees authUser==null until firebase has
                    // updated its state from the session token
                    render={() =>
                        <div style={{flex: 1, flexBasis: 'auto'}}>
                          <AsyncPartnersPage 
                            partners={this.state.partners} 
                            regionID={this.state.regionID} 
                            userRole={this.state.userRole} 
                            debugMode={this.state.debugMode} 
                            onClick={this.updateFancy} 
                            isAdmin={this.state.isAdmin} 
                            isSuperuser={this.state.isSuperuser} 
                            isRegionSuperuser={this.state.isRegionSuperuser} 
                            isPartner={this.state.isPartner} 
                            globalSkills={this.state.globalSkills} 
                            globalSectors={this.state.globalSectors}
                          />
                        </div>}
                  />

                  <Route
                    // For individual opp pages
                    path="/partners/:slug"

                    render={({match}) => (
                      this.state.authUser || this.state.hasLocalStorageUser ? (
                        // This is how we pass stuff (values, vars or functions) to child components.
                        <div style={{flex: 1, flexBasis: 'auto'}}>
                          <AsyncSinglePartner 
                            match={match} 
                            google={this.state.google} 
                            regionID={this.state.regionID} 
                            partners={this.state.partners} 
                            userRole={this.state.userRole} 
                            debugMode={this.state.debugMode} 
                            isAdmin={this.state.isAdmin} 
                            isSuperuser={this.state.isSuperuser} 
                            isRegionSuperuser={this.state.isRegionSuperuser} 
                            isPartner={this.state.isPartner} 
                            globalSkills={this.state.globalSkills} 
                            globalSectors={this.state.globalSectors}
                          />
                        </div>
                      ) : (
                        // Let's just forget about the sign-in page and show the form on the landing page
                        // Unless we move it to a modal.
                        //<Redirect to="/" />
                        // 9th JULY TEMP
                        //<Redirect to="/partners" />

                        //If an auth-protected page has been requested, send the URL so we can redirect
                        //to it after a successful login. Otherwise successful logins go to /opportunities
                        //<Redirect to={{pathname: '/signin', requested: match.url}} />
                        // TO DO - 4th October:
                        // Duplicate above, but to an alternative (public) SinglePartner page that doesn't
                        // list opportunities or hit up the database for anything other than the already-public
                        // partner node.
                        <div style={{flex: 1, flexBasis: 'auto'}}>
                          <AsyncSinglePartnerPublic 
                            match={match} 
                            google={this.state.google} 
                            regionID={this.state.regionID} 
                            partners={this.state.partners} 
                            userRole={this.state.userRole} 
                            debugMode={this.state.debugMode} 
                            isAdmin={this.state.isAdmin} 
                            isSuperuser={this.state.isSuperuser} 
                            isRegionSuperuser={this.state.isRegionSuperuser} 
                            isPartner={this.state.isPartner} 
                            globalSkills={this.state.globalSkills} 
                            globalSectors={this.state.globalSectors}
                          />
                        </div>
                      )
                    )}
                  />

                  <Route
                    exact path="/volunteers"
                    // Redirect to sign-in page if user's not authed
                    // This is breaking page refreshes, as it always sees authUser==null until firebase has
                    // updated its state from the session token
                    render={({match}) => (
                      (this.state.authUser || this.state.hasLocalStorageUser) && (this.state.isPartner || this.state.isAdmin || this.state.isSuperuser || this.state.isRegionSuperuser) ? (
                        // This is how we pass stuff (values, vars or functions) to child components.
                        <div style={{flex: 1, flexBasis: 'auto'}}>
                          <AsyncVolunteersPage 
                            partners={this.state.partners} 
                            regionID={this.state.regionID} 
                            userRole={this.state.userRole} 
                            debugMode={this.state.debugMode} 
                            onClick={this.updateFancy} 
                            isAdmin={this.state.isAdmin} 
                            isSuperuser={this.state.isSuperuser} 
                            isRegionSuperuser={this.state.isRegionSuperuser} 
                            isPartner={this.state.isPartner} 
                            globalSkills={this.state.globalSkills} 
                            globalSectors={this.state.globalSectors}
                          />
                        </div>
                      ) : (
                        // Let's just forget about the sign-in page and show the form on the landing page
                        // Unless we move it to a modal.
                        //<Redirect to="/signin" />
                        //<Redirect to="/" />

                        //If an auth-protected page has been requested, send the URL so we can redirect
                        //to it after a successful login. Otherwise successful logins go to /opportunities
                        <Redirect to={{pathname: '/signin', requested: match.url}} />
                      )
                    )}
                  />

                  <Route
                    // For individual volunteer profiles (visible to Partners)
                    path="/volunteers/:slug"

                    render={({match}) => (
                      (this.state.authUser || this.state.hasLocalStorageUser) && (this.state.isPartner || this.state.isAdmin || this.state.isSuperuser || this.state.isRegionSuperuser) ? (
                        <div style={{flex: 1, flexBasis: 'auto'}}>
                          <AsyncSingleVolunteer 
                            match={match} 
                            google={this.state.google} 
                            regionID={this.state.regionID} 
                            partners={this.state.partners} 
                            userRole={this.state.userRole} 
                            debugMode={this.state.debugMode} 
                            isAdmin={this.state.isAdmin} 
                            isSuperuser={this.state.isSuperuser} 
                            isRegionSuperuser={this.state.isRegionSuperuser} 
                            isPartner={this.state.isPartner} 
                            globalSkills={this.state.globalSkills} 
                            globalSectors={this.state.globalSectors}
                          />
                        </div>
                      ) : (
                        //If an auth-protected page has been requested, send the URL so we can redirect
                        //to it after a successful login. Otherwise successful logins go to /opportunities
                        <Redirect to={{pathname: '/signin', requested: match.url}} />
                      )
                    )}
                  />

                  <Route
                    exact path="/opportunities"
                    // Redirect to sign-in page if user's not authed
                    // This is breaking page refreshes, as it always sees authUser==null until firebase has
                    // updated its state from the session token
                    render={() => (
                      this.state.authUser || this.state.hasLocalStorageUser ? (
                        // This is how we pass stuff (values, vars or functions) to child components.
                        <div style={{flex: 1, flexBasis: 'auto'}}>
                          <OpportunitiesPage 
                            partners={this.state.partners} 
                            regionID={this.state.regionID} 
                            userRole={this.state.userRole} 
                            debugMode={this.state.debugMode} 
                            onClick={this.updateFancy} 
                            isAdmin={this.state.isAdmin} 
                            isSuperuser={this.state.isSuperuser} 
                            isRegionSuperuser={this.state.isRegionSuperuser} 
                            isPartner={this.state.isPartner} 
                            globalSkills={this.state.globalSkills} 
                            globalSectors={this.state.globalSectors}
                          />
                        </div>
                      ) : (
                        // Let's just forget about the sign-in page and show the form on the landing page
                        // Unless we move it to a modal.
                        <Redirect to="/signin" />
                        //<Redirect to="/" />
                      )
                    )}
                  />

                  <Route
                    // For individual opp pages
                    path="/opportunities/:oppID"

                    render={({match}) => (
                      this.state.authUser || this.state.hasLocalStorageUser ? (
                        // This is how we pass stuff (values, vars or functions) to child components.
                        <div style={{flex: 1, flexBasis: 'auto'}}>
                          <AsyncSingleOpp
                            match={match}
                            regionID={this.state.regionID}
                            google={this.state.google}
                            partners={this.state.partners}
                            userRole={this.state.userRole}
                            debugMode={this.state.debugMode}
                            isAdmin={this.state.isAdmin}
                            isSuperuser={this.state.isSuperuser}
                            isRegionSuperuser={this.state.isRegionSuperuser} 
                            isPartner={this.state.isPartner}
                            globalSkills={this.state.globalSkills}
                            globalSectors={this.state.globalSectors}
                          />
                        </div>
                      ) : (
                        //If an auth-protected page has been requested, send the URL so we can redirect
                        //to it after a successful login. Otherwise successful logins go to /opportunities
                        <Redirect to={{pathname: '/signin', requested: match.url}} />

                      )
                    )}
                  />


                  <Route
                    exact path="/newopportunity"
                    // Redirect to sign-in page if user's not authed
                    // This is breaking page refreshes, as it always sees authUser==null until firebase has
                    // updated its state from the session token
                    render={() => (
                      this.state.authUser || this.state.hasLocalStorageUser ? (
                        // This is how we pass stuff (values, vars or functions) to child components.
                        <div style={{flex: 1, flexBasis: 'auto'}}>
                          <AsyncNewOpportunity 
                            partners={this.state.partners} 
                            regionID={this.state.regionID} 
                            userRole={this.state.userRole} 
                            debugMode={this.state.debugMode} 
                            isAdmin={this.state.isAdmin} 
                            isPartner={this.state.isPartner} 
                            globalSkills={this.state.globalSkills} 
                            globalSectors={this.state.globalSectors}
                          />
                        </div>
                      ) : (
                        // Let's just forget about the sign-in page and show the form on the landing page
                        // Unless we move it to a modal.
                        <Redirect to="/signin" />
                        //<Redirect to="/" />
                      )
                    )}
                  />

                  <Route
                    exact path='/profile'
                    // Redirect to sign-in page if user's not authed
                    render={({match}) => (
                        this.state.authUser || this.state.hasLocalStorageUser ? (
                            <div style={{flex: 1, flexBasis: 'auto'}}>
                              <AsyncProfilePage 
                                authUser={this.state.authUser} 
                                regionID={this.state.regionID} 
                                globalSkills={this.state.globalSkills} 
                                loaded={this.state.loaded} 
                                debugMode={this.state.debugMode} 
                                isPartner={this.state.isPartner} 
                                globalSectors={this.state.globalSectors}
                              />
                            </div>
                        ) : (
                          //<Redirect to="/signin" />
                          //<Redirect to="/" />

                          //If an auth-protected page has been requested, send the URL so we can redirect
                          //to it after a successful login. Otherwise successful logins go to /opportunities
                          <Redirect to={{pathname: '/signin', requested: match.url}} />
                        )
                    )}
                  />

                  {/*
                  <Route
                    exact path='/admin'
                    render={() => (
                        this.state.isAdmin && this.state.hasLocalStorageUser ? (
                        <div style={{flex: 1, flexBasis: 'auto'}}><AsyncAdminPage partners={this.state.partners} globalSkills={this.state.globalSkills} debugMode={this.state.debugMode} loaded={this.state.loaded} isSuperuser={this.state.isSuperuser} authUser={this.state.authUser} globalSectors={this.state.globalSectors}/></div>
                        ) : (
                          <Redirect to="/" />
                        )
                      )
                    }
                  />
                  */}

                  <Route
                    exact path='/admin/createuser'
                    render={() => (
                        this.state.isAdmin && this.state.hasLocalStorageUser ? (
                        <div style={{flex: 1, flexBasis: 'auto'}}>
                          <AsyncCreateUserPage 
                            partners={this.state.partners} 
                            regionID={this.state.regionID} 
                            globalSkills={this.state.globalSkills} 
                            debugMode={this.state.debugMode} 
                            loaded={this.state.loaded} 
                            isSuperuser={this.state.isSuperuser} 
                            isRegionSuperuser={this.state.isRegionSuperuser} 
                            authUser={this.state.authUser} 
                            globalSectors={this.state.globalSectors}
                          />
                        </div>
                        ) : (
                          //<Redirect to="/" />
                          <Redirect to="/signin" />
                        )
                      )
                    }
                  />

                  <Route
                    exact path='/admin/manageusers'
                    render={() => (
                        this.state.isAdmin && this.state.hasLocalStorageUser ? (
                        <div style={{flex: 1, flexBasis: 'auto'}}>
                          <AsyncManageUsersPage 
                            partners={this.state.partners} 
                            regionID={this.state.regionID} 
                            globalSkills={this.state.globalSkills} 
                            debugMode={this.state.debugMode} 
                            loaded={this.state.loaded} 
                            isSuperuser={this.state.isSuperuser} 
                            isRegionSuperuser={this.state.isRegionSuperuser} 
                            authUser={this.state.authUser} 
                            globalSectors={this.state.globalSectors}
                          />
                        </div>
                        ) : (
                          //<Redirect to="/" />
                          <Redirect to="/signin" />
                        )
                      )
                    }
                  />

                  <Route
                    // For individual opp pages
                    path="/admin/edituser/:slug"

                    render={({match}) => (
                      this.state.authUser || this.state.hasLocalStorageUser ? (
                        // This is how we pass stuff (values, vars or functions) to child components.
                        <div style={{flex: 1, flexBasis: 'auto'}}>
                          <AsyncEditUser 
                            match={match}
                            history={this.props.history}
                            google={this.state.google}
                            regionID={this.state.regionID}
                            partners={this.state.partners}
                            userRole={this.state.userRole}
                            debugMode={this.state.debugMode}
                            isAdmin={this.state.isAdmin}
                            isSuperuser={this.state.isSuperuser}
                            isRegionSuperuser={this.state.isRegionSuperuser} 
                            isPartner={this.state.isPartner}
                            globalSkills={this.state.globalSkills}
                            authUser={this.state.authUser}
                            globalSectors={this.state.globalSectors}/>
                        </div>
                      ) : (
                        // Let's just forget about the sign-in page and show the form on the landing page
                        // Unless we move it to a modal.
                        <Redirect to="/signin" />
                        //<Redirect to="/" />
                      )
                    )}
                  />

                  <Route
                    exact path='/help'
                    render={() => (
                        this.state.hasLocalStorageUser ? (
                        <div style={{flex: 1, flexBasis: 'auto'}}><AsyncHelpPage 
                          globalSkills={this.state.globalSkills} 
                          loaded={this.state.loaded} 
                          authUser={this.state.authUser} 
                          globalSectors={this.state.globalSectors}/></div>
                        ) : (
                          //<Redirect to="/" />
                          <Redirect to="/signin" />
                        )
                      )
                    }
                  />

                  <Route
                    exact path='/analytics'
                    render={() => (
                        this.state.isAdmin && (this.state.isSuperuser || this.state.isRegionSuperuser) && this.state.hasLocalStorageUser ? (
                        <div style={{flex: 1, flexBasis: 'auto'}}>
                          <AsyncAnalyticsPage 
                            partners={this.state.partners} 
                            regionID={this.state.regionID} 
                            globalSkills={this.state.globalSkills} 
                            debugMode={this.state.debugMode} 
                            loaded={this.state.loaded} 
                            isSuperuser={this.state.isSuperuser} 
                            isRegionSuperuser={this.state.isRegionSuperuser} 
                            authUser={this.state.authUser} 
                            globalSectors={this.state.globalSectors}
                          />
                          </div>
                        ) : (
                          //<Redirect to="/" />
                          <Redirect to="/signin" />
                        )
                      )
                    }
                  />

                  <Route
                    exact path='/config'
                    render={() => (
                        this.state.isAdmin && this.state.isSuperuser && this.state.hasLocalStorageUser ? (
                        <div style={{flex: 1, flexBasis: 'auto'}}>
                          <AsyncConfigPage 
                            partners={this.state.partners} 
                            regionID={this.state.regionID} 
                            globalSkills={this.state.globalSkills} 
                            debugMode={this.state.debugMode} 
                            loaded={this.state.loaded} 
                            isSuperuser={this.state.isSuperuser} 
                            isRegionSuperuser={this.state.isRegionSuperuser} 
                            authUser={this.state.authUser} 
                            globalSectors={this.state.globalSectors}
                          />
                          </div>
                        ) : (
                          //<Redirect to="/" />
                          <Redirect to="/signin" />
                        )
                      )
                    }
                  />

                  <Route
                    exact path='/signin'
                    render={() => (
                        this.state.authUser || this.state.hasLocalStorageUser ? (
                          <Redirect to="/opportunities" 
                            globalSkills={this.state.globalSkills} 
                            regionID={this.state.regionID} 
                            globalSectors={this.state.globalSectors}
                          />
                          ) : (
                            <div style={{flex: 1, flexBasis: 'auto'}}>
                              <SignInPage authFlagHandler={this.authFlagHandler} />
                            </div>
                          )
                      )
                    }

                  />

                  <Route render={() => <div style={{flex: 1, flexBasis: 'auto'}}><PageNotFound /></div>} />

                </Switch>

                <Segment inverted style={{ margin: '5em 0em 0em', padding: '5em 0em' }} vertical>
                  <Container textAlign='center'>
                    <Grid columns={3} divided stackable inverted>
                      <Grid.Row>
                        {/*
                        <Grid.Column>
                          <Header inverted as='h4' content='Group 1' />
                          <List link inverted>
                            <List.Item as='a'>Link One</List.Item>
                            <List.Item as='a'>Link Two</List.Item>
                            <List.Item as='a'>Link Three</List.Item>
                            <List.Item as='a'>Link Four</List.Item>
                          </List>
                        </Grid.Column>
                        <Grid.Column>
                          <Header inverted as='h4' content='Group 2' />
                          <List link inverted>
                            <List.Item as='a'>Link One</List.Item>
                            <List.Item as='a'>Link Two</List.Item>
                            <List.Item as='a'>Link Three</List.Item>
                            <List.Item as='a'>Link Four</List.Item>
                          </List>
                        </Grid.Column>
                        */}
                        <Grid.Column width={16}>
                          <a href="https://rootsandwings.design" target="_blank" rel="noopener noreferrer">
                            <Image src={RawLogo} size='medium' centered verticalAlign='middle'/>
                          </a>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>

                    {/*
                    <Divider inverted section />
                    <List horizontal inverted divided link>
                      <List.Item as='a' href='#'>
                        Site Map
                      </List.Item>
                      <List.Item as='a' href='#'>
                        Contact Us
                      </List.Item>
                      <List.Item as='a' href='#'>
                        Terms and Conditions
                      </List.Item>
                      <List.Item as='a' href='#'>
                        Privacy Policy
                      </List.Item>
                    </List>
                    */}
                  </Container>
                </Segment>

              </div>
            </Router>

          )

    )
  }

}

export default App;
