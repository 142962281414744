import React, { Component } from 'react';

import { database } from '../../firebase/firebase';
import { Link } from 'react-router-dom';
import sanitizeHTML from 'sanitize-html';
import { slugify } from '../../lib/user';
//import { slugify } from '../lib/user';
import _ from 'lodash';
import moment from 'moment';
import { Dimmer, Loader, Grid, Container,
         Item, Button, List, Header, Segment, Transition, Message, Icon,
         Checkbox, Visibility } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';


const cleanHTML = (input) => {
  var output = sanitizeHTML(input, {
    // Strip out ALL tags for front-page display listing!
    allowedTags: ['i'],
    allowedAttributes: {
      a: ['href', 'target']
    }
  })
  return output;
}


const buildState = () => ({
  opps: [],
  oppDisplayLimit: 8,
  oppDisplayPageLength: 8,
  loadMoreSpinner: false,
  userSkillsFilters: [],
  userSearchFilters: [''],
  filterBySkills: true,
  loaded: true,
  currentDateStamp: 0 + new Date().setHours(0,0,0,0), //Midnight this morning
  oppNotFoundMessage: false,
})

class OpportunitiesPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...buildState() };

  }

  componentDidMount() {

    console.log(this.props.location.search);
    if(this.props.location.search && this.props.location.search.indexOf('oppnotfound') > -1) {
      this.setState({oppNotFoundMessage: true}, () => {
        setTimeout(() => {
          this.setState({oppNotFoundMessage: false})
          this.props.history.push({
            pathname: '/opportunities',
            search: ''
          })
        },3000)
      })
    }

    // Get all the opportunities and bung them in the component state array 'opps'
    const oppsRef = database.ref('/opportunities');
    oppsRef.on('value', (snapshot) => {
      let newState = [];
      let todayStamp = this.state.currentDateStamp;

      snapshot.forEach(function(opp) {
        var tmp = opp.val();

        var currentlyLive = false;
        if(tmp.creationDate <= todayStamp && tmp.expiryDate > todayStamp) {
          currentlyLive = true;
        }

        console.log(tmp);        

        newState.push({
          oppID: opp.key, // use the unique FB push ID as the key, for React's map later
          title: tmp.oppName,
          partner: tmp.partnerName,
          partnerSlug: slugify(tmp.partnerName), // Generating this on the fly makes ownership-changes more stable
          description: cleanHTML(tmp.description),
          location: tmp.location,
          postcode: tmp.postcode,
          recruitment: tmp.recruitment,
          reqLanguages: tmp.reqLanguages,
          requiredSkills: tmp.requiredSkills,
          support: tmp.support,
          timings: tmp.timings,
          creationDate: tmp.creationDate,
          expiryDate: tmp.expiryDate,
          contactEmail: tmp.contactEmail,
          thumbImage: tmp.thumbnail,
          editModal: false,
          partnerID: tmp.partnerID,
          oppIsLive: currentlyLive,
          urlSlug: tmp.urlSlug, // Now redundant; left in just in case
          published: tmp.published,
        });


      })
      newState.reverse();
      this.setState({
        opps: newState,
      });

      // Before we set loaded: true, get the user's skills and search filters. If there are none
      // (e.g. because they're admin or partner), that's fine - the skillsMatch function will just
      // show everything.
      /*
      database.ref('/usersReadable/' + localStorage.getItem('fbUID')).on('value', (snapshot) => {
        var tmp = snapshot.val();
        console.log(tmp.skills)
        this.setState({userSkillsFilters: tmp.skills})

      })*/
      document.title = 'skillsmatch | Opportunities';
      this.setState({loaded: true});


    });

  }


  skillsMatch = (opp) => {
    //console.log("Required skills");
    //console.log(opp.requiredSkills);
    var oppSkills = opp.requiredSkills;

    if(this.state.filterBySkills && !this.props.isAdmin && !this.props.isPartner && 'skillsFilters' in localStorage) {

      var temporaryUserSkills = localStorage.getItem('skillsFilters').split(',');

      for (var skill in temporaryUserSkills) {
        // Go through all of the user's saved skill searches and return a true as soon as this opp
        // is discovered to be tagged with one.
        if(oppSkills && oppSkills.indexOf(temporaryUserSkills[skill]) > -1) {
          return true;
        }
      }
    } else {
      // Filtering disabled, so let 'em all through
      return true;
    }
  }

  checkLive = (opp) => {
    if(this.props.isSuperuser) {
      return true
    } else if(this.props.isPartner) {
      if(opp.partnerID === localStorage.getItem('fbUID')) {
        return true
      }
    } else {
      if(opp.creationDate <= this.state.currentDateStamp && opp.expiryDate > this.state.currentDateStamp && opp.published) {
        return true
      }
    }
  }

  handleInfiniteScroll = () => {
    var tmp = this.state.oppDisplayLimit + this.state.oppDisplayPageLength;
    console.log("bottom hit, tmp is " + tmp);
    console.log("opps length: " + this.state.opps.length);
    console.log("opp display limit: " + this.state.oppDisplayLimit)
    if(tmp < this.state.opps.length-1) {
      this.setState({loadMoreSpinner: true})
      // This is just for show - we've already got all of the active Opportunities from the database
      // (there should never be an unmanageable quantity, given the project's scope) so we're just
      // allowing the display loop to render more of them. Short burst of loading spinner signals
      // that something new is arriving.
      setTimeout(() => {
          this.setState({oppDisplayLimit: tmp, loadMoreSpinner: false});
      }, 250)
    } else {
      // Could show a "You've reached the end"-type message at the bottom?
      // Make absolutely sure the display limit exceeds the opp array size:
      this.setState({oppDisplayLimit: tmp});
    }

  }


  formatDate = (input) => {
    moment.locale('en-gb');
    var date = moment(input).format('Do MMMM YYYY');
    return date;
  }




  render() {

    if(!this.state.loaded) return(
      <Dimmer inverted active>
        <Loader inverted size='huge' content='Loading' />
      </Dimmer>
    );

    if(this.state.oppNotFoundMessage) return(
      <Container text>
        <Message warning floating>
          <Message.Header><Icon name='warning'/>Opportunity not found</Message.Header>
          <p>Sorry, this opportunity may have expired or been removed.</p>
        </Message>
      </Container>
    )

    return(
      <Visibility continuous onBottomVisible={() => this.handleInfiniteScroll()}>
        <Container text>


          {this.props.isPartner || this.props.isAdmin ? (
              <Container text textAlign='center'>
                <br/><br/>
                  <Button color='orange' size='big'  basic as={Link} to='/newopportunity' >Create New Opportunity</Button>
                <br/><br/>
              </Container>
          ) : (

            <Segment basic textAlign='center'>
              <h1>Volunteering Opportunities</h1>
            </Segment>

          )}


          <Grid.Row>
          <Grid.Column>
              <br/>


              {this.props.isPartner || this.props.isSuperuser || this.props.isAdmin ? (
                this.props.isPartner ? (
                  <Header as='h2'>Opportunities published by {localStorage.getItem('partnerName')}</Header>
                ) : (
                  null
                )
              ): (
                // Not a partner, so show the search/filter stuff:
                <div>
                  {/*<p>
                    The <strong><i>skills</i>match</strong> filter shows you opportunities that match one or more of your skills!<br/>
                    You can edit your skills on your <Link to='/profile'>Profile page</Link>.
                  </p>*/}
                  <Checkbox color='orange' toggle label={<label>Use <strong><i>skills</i>match</strong> filter? </label>}
                    //onChange={(event, data) => this.setState(byPropKey('newUserEnglishGood', data.checked))}
                    onChange={(event, data) => this.setState({filterBySkills: data.checked})}
                    checked={this.state.filterBySkills}
                  />
                  <br/><br/> Showing {
                    this.state.filterBySkills ? (
                      <span>
                        <strong>{this.state.opps.filter(this.checkLive).filter(this.skillsMatch).slice(0, this.state.oppDisplayLimit).length}</strong> of <strong>{this.state.opps.filter(this.checkLive).length}</strong> opportunities.
                      </span>
                    ) : (
                      <span>
                        <strong>{this.state.opps.filter(this.checkLive).slice(0, this.state.oppDisplayLimit).length}</strong> of <strong>{this.state.opps.filter(this.checkLive).length}</strong> opportunities.
                      </span>
                    )
                  }


                  <br/><br/>
                </div>
              )}

              <Item.Group relaxed>
                {this.state.opps.filter(this.checkLive).filter(this.skillsMatch).slice(0, this.state.oppDisplayLimit).map((opp) => {
                  return (

                      <Item key={opp.oppID} >
                        { opp.creationDate <= this.state.currentDateStamp && opp.expiryDate > this.state.currentDateStamp && opp.published
                          ? <Item.Image as={Link} to={'/opportunities/' + opp.oppID} size='medium' src={opp.thumbImage} />
                          : (opp.published
                            ? <Item.Image as={Link} to={'/opportunities/' + opp.oppID} size='medium' src={opp.thumbImage} label={{color: 'red', content: 'Expired', ribbon: true}} />
                            : <Item.Image as={Link} to={'/opportunities/' + opp.oppID} size='medium' src={opp.thumbImage} label={{color: 'red', content: 'Unpublished', ribbon: true}} />
                          )
                        }

                        <Item.Content verticalAlign='top'>
                          <Item.Header style={{textTransform: 'uppercase', fontWeight: 300}} as={Link} to={'/opportunities/' + opp.oppID}>{opp.title}</Item.Header>
                          <Item.Extra style={{fontSize: '12px'}}>
                            Posted on <strong>{this.formatDate(opp.creationDate)}</strong>by <strong><a href={'partners/' + opp.partnerSlug}>{opp.partner}</a></strong>
                            <br/>
                          </Item.Extra>
                          <Item.Description>
                            <List>
                              <List.Item>

                                <div dangerouslySetInnerHTML={{__html: _.truncate(opp.description.replace(/\n/g, ' '), {'length': 200, 'separator': ' ', 'omission': ' [...]'}) }} />
                                <br/><br/>
                                <span style={{color: '#eb982a'}}><a href={'/opportunities/' + opp.oppID}>read more</a></span>
                                <br/><br/><br/><br/>
                              </List.Item>
                            </List>
                          </Item.Description>

                        </Item.Content>
                      </Item>


                  )
                })}

              </Item.Group>

              <Loader active={this.state.loadMoreSpinner} inline='centered' />

            </Grid.Column>
          </Grid.Row>
        </Container>
      </Visibility>
    );

  }
}

export default withRouter(OpportunitiesPage)
