import React, { Component } from 'react';
import { Image, Container, Grid, Message} from 'semantic-ui-react';
//import CafeLadies from '../../res/skillsmatch_cafe_ladies.jpg';
import LogoWers from '../../res/logos/wers.png';
import LogoNcl from '../../res/logos/ncl.png';
import LogoRaw from '../../res/logos/raw.png';
import LogoCB from '../../res/logos/cblogo.jpg';
import LogoShears from '../../res/logos/shearslogo.jpg';
//import ByRaw from '../../res/by-raw-800.png';
import LogoTees from '../../res/logos/teeside.png';
import LogoFuse from '../../res/logos/creativefuse.png';
import ReportPDF from '../../res/Skillsmatch_Report.pdf';
import BuddyRTF from '../../res/WERS_Volunteer_Application_Form.rtf';


export default class AboutPage extends Component {



    render() {
      document.title = 'skillsmatch | About';
      // Conditional rendering shows the sign-in form if user isn't authed, or not if they are.
      return (
        <div style={{background: '#ffffff'}}>


          <div className='frontpage-banner wanttovolunteer-banner' id="for-volunteers">
            <div className="frontpage-banner-overlay">
              <div className="frontpage-banner-text">Want To Volunteer?</div>
            </div>
          </div>


          <Container text>
            <Grid>
              <Grid.Row>
                <Grid.Column>

                  <br/><br/>
                  <h2 >Information for Volunteers</h2>

                  <p>
                  Are you an asylum seeker or refugee who is interested in volunteering?
                  </p>

                  <p>
                  Volunteering can be a great way to apply or develop your existing skills and interests.
                  You can meet new people and improve your English. Helping out at a local organisation
                  can improve mental health and well-being. It can be an enjoyable and rewarding way to
                  spend your time - giving back to your local community and adding some routine to your
                  week, especially if you are not in a position to work.
                  </p>

                  <h2>How <i>skills</i>match works</h2>
                  <p>
                  <i>skills</i>match can help you find interesting volunteering opportunities that match your
                  individual skills and interests. We work with organisations in the Newcastle area who
                  welcome applications from asylum seekers and refugees.
                  </p>

                  <h2>How to get involved</h2>
                  <p>
                  Register for <i>skills</i>match at West End Refugee Service (WERS).
                  <br/><br/>
                  Come to one of our drop-ins on a Monday or Wednesday 10am-1pm and ask to
                  sign up to a <i>skills</i>match session. For full details of where we are please visit
                  the website: <a href="http://www.wers.org.uk/contact-us/">http://www.wers.org.uk/contact-us</a>
                  <br/><br/>

                   We will then contact you to invite you to attend a <i>skills</i>match session, this is a one-to-one
                   appointment with one of our volunteer buddies.

                   <br/><br/>

                   At skillsmatch sessions, our volunteer buddies will support you to:
                  </p>
                  <ul>
                    <li>
                      Create a volunteer profile (write about your skills and interests and why you would like to volunteer)
                    </li>
                    <li>
                      Search for suitable volunteer opportunities
                    </li>
                    <li>
                      Contact & apply for opportunities and/or organise visits to organisations
                    </li>
                    <li>
                      Share your profile with organisations that you choose
                    </li>
                  </ul>

                  <Message>
                    <Message.Header>COVID UPDATE</Message.Header>
                    These sessions will be undertaken with masks, screens, hand sanitiser and
                    regular cleaning to keep everyone safe.
                  </Message>

                  <p>
                    When you create your profile, you’ll create a unique user ID and password. This allows you to
                    make updates to your own profile in the future.
                  </p>

                  <br/><br/><br/>

                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>


          <div className='frontpage-banner needavolunteer-banner' id="for-organisations">
            <div className="frontpage-banner-overlay">
              <div className="frontpage-banner-text">Looking For Volunteers?</div>
            </div>
          </div>


          <Container text>
            <Grid>
              <Grid.Row>
                <Grid.Column>

                <br/><br/>
                <h2>Information for Organisations</h2>

                <p>
                  If you are interested in participating in <i>skills</i>match, please contact us at <a href='mailto:skillsmatch@wers.org.uk'>skillsmatch@wers.org.uk</a>

                  <br/><br/>
                  We will then make contact with you and arrange a visit or a call/zoom meeting
                  to find out more about your work your volunteer roles. We also provide
                  information about working with asylum seekers and refugees as volunteers and
                  how the project works.
                  <br/><br/>

                  If your organisation is interested in participating, you can register with the project.

                </p>

                <h2>How <i>skills</i>match works</h2>
                <p>

                  We’ll provide you with a <strong>unique user ID and password</strong> so that you can submit a short profile for your organisation and post up any volunteer opportunities.
                  <br/><br/>
                  We can then <strong>advertise your volunteer opportunities</strong> (on this website, and at weekly one-to-one supported sessions), act as an initial point of contact for interested people and put prospective volunteers in touch with you.
                  <br/><br/>
                  We have skillsmatch <strong>buddies</strong> who can help volunteers search, apply for opportunities and/or accompany them on their first visit to you.
                  <br/><br/>
                  We also organise <strong>group volunteering trips</strong>, where we can bring a group of individuals to help your organisation complete or deliver a task, specific activity or event.
                  <br/><br/>
                </p>

                  <Message><Message.Header>COVID UPDATE</Message.Header>
                    Any visits or activity will be undertaken in a Covid safe manner with appropriate
                    safety measures in place. We are limiting group trips to small numbers and where
                    possible we organise activity in outdoor settings.
                  </Message>
                <p>
                  We have some <strong>quality standards</strong> that we ask organisations to agree to when they register with the project.  Briefly, we will ask that your organisation:
                </p>

                <ul>
                  <li>
                    Welcomes volunteers from asylum seeker and refugee backgrounds
                  </li>
                  <li>
                    Is willing to support asylum seekers and refugees to engage with volunteering opportunities and activities
                  </li>
                  <li>
                    Ensures that volunteers are safe and properly insured
                  </li>
                  <li>
                    Provides appropriate training and support
                  </li>
                  <li>
                    Does not use volunteers to replace paid staff
                  </li>
                  <li>
                    Can reimburse volunteer expenses wherever possible
                  </li>
                </ul>

                <p>
                  If you’d like to get in touch please email <a href='mailto:skillsmatch@wers.org.uk'>skillsmatch@wers.org.uk</a>  or call 0191 273 7482.
                </p>


                <br/><br/><br/>

                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>


          <div className='frontpage-banner buddies-banner' id="for-buddies">
            <div className="frontpage-banner-overlay">
              <div className="frontpage-banner-text">Become a <i>skills</i>match buddy?</div>
            </div>
          </div>


          <Container text>
            <Grid>
              <Grid.Row>
                <Grid.Column>

                <br/><br/>
                <h2>Become a <i>skills</i>match buddy</h2>

                <p>
                  Would you like to volunteer as a <i>skills</i>match buddy to provide essential support to individual
                  clients who are interested in finding and accessing volunteering opportunities through the
                  <i>skills</i>match project?

                  <br/><br/>
                  This is a rewarding role where you will assist individuals in creating their online profile on the
                  <i>skills</i>match website (this may include setting up an email address), and in choosing an
                  appropriate volunteering opportunity from our current opportunities. You may then provide support
                  with contacting the organisation, visiting to look round and attending for an interview or induction
                  session - depending on how much help individuals would like. You may also help with gathering feedback
                  from client volunteers, making phone calls to follow client progress, and collating project data.
                  Volunteering will take place at WERS, at external drop-ins in Newcastle and around Newcastle City Centre
                  at various volunteering locations. Volunteering sessions will always be supervised by a WERS volunteer coordinator.

                </p>

                <h2>Requirements:</h2>
                <p>

                  We ask that you have a few hours free at least once a fortnight during a weekday and that
                  you are willing to make a commitment of 6 months to volunteering with the project. You’ll
                  need good IT skills (web, email, Microsoft Office), communication and listening skills and
                  have the ability to work sensitively. We’ll ask that you are able to provide support in a non-
                  judgemental, consistent and reliable way. We will provide comprehensive induction training
                  and you will receive ongoing support from a WERS volunteer coordinator.
                </p>

                <h2>How to apply:</h2>
                <p>
                  Please complete a <a href={BuddyRTF}>WERS application form, which you can download here</a>, selecting 'skillsmatch buddy'
                  as the role you are interested in. Send to
                  <a href='mailto:volunteers@wers.org.uk'> volunteers@wers.org.uk</a> and we will get in touch.
                </p>


                <br/><br/><br/>

                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>



          <div className='frontpage-banner projectbackground-banner' id='project-background'>
            <div className="frontpage-banner-overlay">
              <div className="frontpage-banner-text">Project Background</div>
            </div>
          </div>


          <Container text>
            <Grid>
              <Grid.Row>
                <Grid.Column>

                  <br/><br/>
                  <p>
                    <i>skills</i>match was created in 2018 as a Creative Fuse Innovation Pilot, in a partnership between West End Refugee Service (WERS), local creative agency Roots and Wings and Newcastle University Business School and Teesside University School of Social Sciences.  This website was developed in close consultation with a group of WERS’ clients and a range of local organisations.
                  </p>

                  <p>
                    You can read more about how skillsmatch was initially developed in <a href={ReportPDF}>this project
                    report (PDF file)</a>, published in June 2018.
                  </p>
                  <br/><br/><br/>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column width={10}>
                  Partnership: <br/><br/>
                  <Image src={LogoWers} inline size='small' href='http://www.wers.org.uk/' />

                  <Image src={LogoRaw} inline size='small' href='http://www.rootsandwings.design/' />

                  <Image src={LogoNcl} inline size='small' href='https://www.ncl.ac.uk/' />

                  <Image src={LogoTees} inline size='small' href='http://www.tees.ac.uk/' />
                </Grid.Column>

                <Grid.Column width={6}>
                  Funded by: <br/><br/><br/>
                  <Image src={LogoFuse} inline size='medium' href='http://www.creativefusene.org.uk/' />
                </Grid.Column>
              </Grid.Row>

            </Grid>
          </Container>



        </div>

      )

    }
}
