import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Icon, Button, Header, Responsive, Image, Container, Menu, Dropdown } from 'semantic-ui-react'

import { createMedia } from "@artsy/fresnel";

import { auth } from '../../firebase';
import menuLogo from '../../res/skillsmatch_menu_logo.png';

const AppMedia = createMedia({
  breakpoints: {
    mobile: 320,
    computer: 768
  }
});

const mediaStyles = AppMedia.createMediaStyle();
const { Media, MediaContextProvider } = AppMedia;

const menuStyle = {
  border: 'none',
  borderRadius: 0,
  boxShadow: 'none',
  marginBottom: '1em',
  //marginTop: '4em',
  transition: 'box-shadow 0.5s ease, padding 0.5s ease',
}

const fixedMenuStyle = {
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
}
/*
const overlayStyle = {
  float: 'left',
  margin: '0em 3em 1em 0em',
}

const fixedOverlayStyle = {
  ...overlayStyle,
  position: 'fixed',
  top: '80px',
  zIndex: 10,
}


const overlayMenuStyle = {
  position: 'relative',
  left: 0,
  transition: 'left 0.5s ease',
}

const fixedOverlayMenuStyle = {
  ...overlayMenuStyle,
  left: '800px',
}
*/

export default class Navigation extends Component {



  constructor(props) {
    super(props);

    this.state = {
      menuFixed: false,
      confirmOpen: false,
    }

  }


  stickTopMenu = () => this.setState({ menuFixed: true })
  unStickTopMenu = () => this.setState({ menuFixed: false })
  handleItemClick = (e, { name }) => this.setState({activeItem: name})

  handleSignOut = () => {
    this.setState({confirmOpen: false});
    auth.doSignOut();
    this.props.onLogOut();
  }

  componentDidMount() {
    this.setState({activeItem: window.location.pathname})
  }

  render(){

    const {menuFixed, activeItem} = this.state


    return (


        <Menu borderless stackable  style={menuFixed ? fixedMenuStyle : menuStyle} >
          <Container className='nav-container'>

            <Modal style={{position: 'static'}} /*  IE11 fix, 2019-06-17  */ basic size='mini' open={this.state.confirmOpen}>
              <Header icon='sign-out' content={<span>Sign out of <i>skills</i>Match?</span>} />
              <Modal.Actions>
                <Button basic inverted onClick={() => this.setState({confirmOpen: false})}>
                  <Icon name='remove' /> No
                </Button>
                <Button color='orange' basic inverted onClick={this.handleSignOut}>
                  <Icon name='checkmark' /> Yes
                </Button>
              </Modal.Actions>
            </Modal>

            <Menu.Item position='left' fitted='horizontally' as={Link} to='/opportunities'
              name='/opportunities' active={activeItem === '/opportunities'} onClick={this.handleItemClick}
              className="menu-logo-nohover"
              >
               <style>{mediaStyles}</style>
               <MediaContextProvider>

               <Image as={Media} at="mobile" size='medium'  src={menuLogo} style={{paddingLeft: '1em'}}/>
              
                <Image as={Media} at="computer" size='medium'  src={menuLogo} />

               </MediaContextProvider>
              
              
              

            </Menu.Item>


            { this.props.authUser
              ?
                <React.Fragment>
                  <Menu.Item as={Link} to='/opportunities' name='/opportunities' active={activeItem === '/opportunities'} onClick={this.handleItemClick}>
                    OPPORTUNITIES
                  </Menu.Item>

                  <Menu.Item as={Link} to='/partners' name='/partners' active={activeItem === '/partners'} onClick={this.handleItemClick}>
                    PARTNERS
                  </Menu.Item>

                  { this.props.isPartner || this.props.isAdmin || this.props.isSuperuser
                  ?
                    (
                    <Menu.Item as={Link} to='/volunteers' name='/volunteers' active={activeItem === '/volunteers'} onClick={this.handleItemClick}>
                      VOLUNTEERS
                    </Menu.Item>
                    ) : (null)
                  }




                  { !this.props.isAdmin || !this.props.isSuperuser ?
                      <Menu.Item as={Link} to='/profile' name='/profile' active={activeItem === '/profile'} onClick={this.handleItemClick}>
                        PROFILE
                      </Menu.Item> :
                      null

                  }


                  { this.props.isAdmin
                      ?

                      <Dropdown simple item text='ADMIN'>
                        <Dropdown.Menu>
                          <Dropdown.Item as={Link} text='Create User' to='/admin/createuser' name='/admin/createuser' active={activeItem === '/admin/createuser'} onClick={this.handleItemClick}></Dropdown.Item>
                          <Dropdown.Item as={Link} text='Manage Users' to='/admin/manageusers' name='/admin/manageusers' active={activeItem === '/admin/manageusers'} onClick={this.handleItemClick}></Dropdown.Item>
                          { this.props.isRegionSuperuser ?
                            <Dropdown.Item as={Link} text='Analytics' to='/analytics' name='/analytics' active={activeItem === '/analytics'} onClick={this.handleItemClick}></Dropdown.Item>
                          : null}                          
                          <Dropdown.Item as={Link} text='Help' to='/help' name='/help' active={activeItem === '/help'} onClick={this.handleItemClick}></Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      :
                      null
                  }

                  { this.props.isSuperuser
                    ?
                    <Dropdown simple item text='SUPERUSER'>
                      <Dropdown.Menu>                                                    
                          <Dropdown.Item as={Link} text='Analytics' to='/analytics' name='/analytics' active={activeItem === '/analytics'} onClick={this.handleItemClick}></Dropdown.Item>                          
                          <Dropdown.Item as={Link} text='Config' to='/config' name='/config' active={activeItem === '/config'} onClick={this.handleItemClick}></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    :
                    null

                  }


                  <Menu.Item onClick={() => this.setState({confirmOpen: true})}>
                    SIGN OUT
                  </Menu.Item>


                </React.Fragment>
              :

                <React.Fragment>
                
                <Menu.Item as={Link} to='/signin' name='signin' active={activeItem === '/signin'} onClick={this.handleItemClick}>
                  SIGN IN
                </Menu.Item>
                
                </React.Fragment>
            }

          </Container>
        </Menu>

    )
  }

}
