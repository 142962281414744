import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
//import { Row, Col, Button, Form, FormGroup, Label, Input, Container } from 'reactstrap';
import { Accordion, Input, Icon, Message, Container, Grid, Button, Form } from 'semantic-ui-react';

import { auth } from '../../firebase';

/*
const SignInPage = ({ history }) =>
  <div>
    <h1>Sign in Page</h1>
    <SignInForm history={history} authHandler={this.authFlagHandler} />
    <SignUpLink />
  </div>
  */

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  forgotPasswordEmail: '',
  forgotActive: false,
  forgotPasswordValid: false,
  resetSuccessMessageActive: false,
  resetFailureMessageActive: false,
}

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };

  }


  onSubmit = (event) => {
    const {
      email,
      password,
    } = this.state;

    /*
    const {
      //history,
      //authHandler,
    } = this.props;
    */

    auth.doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.authFlagHandler(); //callback to parent to set auth true, so that history push will work.
        // As long as the signin page isn't refreshed, any auth-protected page that was initially
        // requested will be preserved after login.
        if(this.props.location.requested) {
          this.props.history.push(this.props.location.requested);
        } else {
          this.props.history.push('/opportunities');
        }

      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });

      event.preventDefault();
  }

  expandForgot = (e) => {
    if(this.state.forgotActive) {
        this.setState({forgotActive: false})
    } else {
      this.setState({forgotActive: true})
    }
    e.preventDefault();
  }

  handleForgotPassword = (e) => {
    var inp = e.target.value;
    this.setState({forgotPasswordEmail: inp});
    if(validateEmail(inp)) {
      this.setState({forgotPasswordValid: true});
    } else {
      this.setState({forgotPasswordValid: false});
    }
  }

  makeResetRequest = () => {
    this.setState({resetFailureMessageActive: false});
    console.log("making request");
    auth.sendPasswordResetEmail(this.state.forgotPasswordEmail).then(() => {
      this.setState({resetSuccessMessageActive: true});
    }).catch((error) => {
      console.log("Error: ", error);
      this.setState({resetFailureMessageActive: true});
    })

    /*
    fetch('https://www.googleapis.com/identitytoolkit/v3/relyingparty/getOobConfirmationCode?key=AIzaSyCnBX2S4t7oHMWf8GwD8czSh5826oW7xu4', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        'kind': 'identitytoolkit#relyingparty',
        'requestType': 'PASSWORD_RESET',
        'email': this.state.forgotPasswordEmail
      }
    }).then((response) => {
      console.log(response);
    }).then((data) => {
      console.log(data);
    })
    */
  }


  render() {
    const {
      email,
      password,
      error
    } = this.state;

    const isInvalid =
      password === '' ||
      email === '';


    return (

      <Container text>
      <br/><br/>
        <Grid>
          <Grid.Row>
            <Grid.Column>

              <Message attached header='' content='Please log in with your email address and password'/>
              <Form className='attached fluid segment' onSubmit={this.onSubmit}>
                <Form.Field>
                  <label >Email</label>
                  <input type="email"
                         value={email}
                         placeholder="Email"
                         //bsSize="sm"
                         onChange={event => this.setState(byPropKey('email', event.target.value))}
                  />
                </Form.Field>
                <Form.Field>
                  <label >Password</label>
                  <input type="password"
                         value={password}
                         placeholder="Password"
                         //bsSize="sm"
                         onChange={event => this.setState(byPropKey('password', event.target.value))}
                  />
                </Form.Field>

                { error &&

                  <Message negative>
                    <Message.Header>Sorry, there was a problem:</Message.Header>
                    <p>{error.message}</p>
                  </Message>
                }

                <Button basic icon type="submit" color='orange' disabled={isInvalid} ><Icon name='sign-in'/> Sign In</Button>

              </Form>
              <Message attached='bottom' warning>



                <Accordion>
                  <Accordion.Title active={this.state.forgotActive} onClick={(event) => this.expandForgot(event)}>
                    <Icon name='help' /> Forgotten your password? Click here...
                  </Accordion.Title>
                  <Accordion.Content active={this.state.forgotActive}>
                    <p>
                      {"Enter your email address below and we'll send you an email containing instructions on how to reset your password."}
                    </p>
                    <Input type='text' size='mini' value={this.state.forgotPasswordEmail}
                      onChange={(event) => this.handleForgotPassword(event)}
                      placeholder={"Email address"}
                      action={<Button size='small' disabled={!this.state.forgotPasswordValid} onClick={this.makeResetRequest}>Submit</Button>}
                      />
                    <br/>
                    <Message error hidden={!this.state.resetFailureMessageActive}>
                      <Message.Header>Error</Message.Header>
                      <p>{"This email is not in the database. The user account may have been deleted, or perhaps you mistyped your address?"}</p>
                    </Message>
                    <Message success hidden={!this.state.resetSuccessMessageActive}>
                      <Message.Header>Thanks</Message.Header>
                      <p>{"A password reset email will be sent to you soon!"}</p>
                    </Message>

                  </Accordion.Content>
                </Accordion>
                <br/>
                <Icon name='info' /> Not yet registered as a Volunteer or Partner but want to find out more? <a href="https://www.skillsmatch.me">Click here...</a>

              </Message>

            </Grid.Column>

          </Grid.Row>
        </Grid>
      </Container>
    )

  }

}

export default withRouter(SignInForm);

export {
  SignInForm,
}
