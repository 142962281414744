import firebase from 'firebase/compat/app';
require('firebase/compat/auth');
require('firebase/compat/database');
require('firebase/compat/functions');
require('firebase/compat/storage');

/*
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';
import 'firebase/storage';*/


const config = {
  apiKey: "AIzaSyCnBX2S4t7oHMWf8GwD8czSh5826oW7xu4",
  authDomain: "skillsmatch-355f3.firebaseapp.com",
  databaseURL: "https://skillsmatch-355f3.firebaseio.com",
  projectId: "skillsmatch-355f3",
  storageBucket: "skillsmatch-355f3.appspot.com",
  messagingSenderId: "1046840897180"
}

if(!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();
const database = firebase.database();
const functions = firebase.functions();
const storage = firebase.storage();

export {
  auth,
  database,
  functions,
  storage
};
