import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import registerServiceWorker from './registerServiceWorker';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import './dist/semantic.min.css';
import './fomantic/dist/semantic.min.css'
import './bren-overrides.css';

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
