import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import { Container, Header, Icon } from 'semantic-ui-react';

class PageNotFound extends Component {

  constructor(props) {
    super(props);

    this.state = {

    }
  }

  componentDidUpdate() {
    //console.log(this.state.text);
  }

  showEditor = () => {
    this.setState({showEditor: true});
  }


  render() {

    return (
      <Container text>
        <Header as='h1' icon textAlign='center'>
          <br/><br/>
          <Icon name='cogs' circular />
          <Header.Content><br/>404 <br/><br/> PAGE NOT FOUND</Header.Content>
        </Header>
      </Container>
    )

  }

}
export default withRouter(PageNotFound)
