import React, { Component } from 'react';
import { Image, Container, Grid, Message,
         Icon, Segment, Button, Modal, List,
        Divider} from 'semantic-ui-react';
import SRLWrapper from 'simple-react-lightbox';
//import CafeLadies from '../res/skillsmatch_cafe_ladies.jpg';
import LogoWers from '../../res/logos/wers.png';
import LogoNcl from '../../res/logos/ncl.png';
import LogoRaw from '../../res/logos/raw.png';
import LogoCB from '../../res/logos/cblogo.jpg';
import LogoShears from '../../res/logos/shearslogo.jpg';
//import ByRaw from '../../res/by-raw-800.png';
import LogoTees from '../../res/logos/teeside.png';
import LogoFuse from '../../res/logos/creativefuse.png';
import ReportPDF from '../../res/Skillsmatch_Report.pdf';
import BuddyRTF from '../../res/WERS_Volunteer_Application_Form.rtf';

import Bfd1 from '../../res/casestudies/bfd1.jpg';
import Bfd2 from '../../res/casestudies/bfd2.jpg';
import Img1 from '../../res/casestudies/1.jpg';
import Img2 from '../../res/casestudies/2.jpg';
import Img3 from '../../res/casestudies/3.jpg';
import Img4 from '../../res/casestudies/4.jpg';
import Img5 from '../../res/casestudies/5.jpg';
import Img6 from '../../res/casestudies/6.jpg';
import Img7 from '../../res/casestudies/7.jpg';
import Img8 from '../../res/casestudies/8.jpg';
import Img9 from '../../res/casestudies/9.jpg';
import Img10 from '../../res/casestudies/10.jpg';
import Img11 from '../../res/casestudies/11.jpg';
import Img12 from '../../res/casestudies/12.jpg';
import Img13 from '../../res/casestudies/13.jpg';
import Img14 from '../../res/casestudies/14.jpg';
import Img15 from '../../res/casestudies/15.jpg';
import Img16 from '../../res/casestudies/16.jpg';
import Img17 from '../../res/casestudies/17.jpg';


export default class AboutPage extends Component {

    constructor(props) {
      super(props);
      this.state = {
        modalOpen: false,
        lightboxUrl: '',
        lightboxCaption: '',
        bfd1: {
          src: Bfd1,
          caption: 'Baltic Family Days'
        },
        bfd2: {
          src: Bfd2,
          caption: 'Baltic Family Days'
        },
        tripsgallery: [
          {
            src: Img1,
            caption: 'Raspberry picking at Lanchester Community Orchard'
          },
          {
            src: Img2,
            caption: 'Haltwhistle Plant Festival team'
          },
          {
            src: Img3,
            caption: 'Minsteracres gardening'
          },
          {
            src: Img4,
            caption: 'Baltic ladies'
          },
          {
            src: Img5,
            caption: 'Baltic Family Days'
          },
          {
            src: Img6,
            caption: 'Baltic Family Days'
          },
          {
            src: Img7,
            caption: 'D6 Group Visits'
          }
        ],
        img8: {
          src: Img8,
          caption: 'Lives Tales volunteer illustrator'
        },
        img9: {
          src: Img9,
          caption: 'Volunteer at Oxfam'
        },
        img10: {
          src: Img10,
          caption: 'Scotswood Community Group with Sean'
        },
        img11: {
          src: Img11,
          caption: 'Lives Tales illustrator volunteer at Live Theatre'
        },
        miscgallery: [
          {
            src: Img12,
            caption: 'St Vincent De Paul volunteers\' week'
          },
          {
            src: Img13,
            caption: 'Minsteracres volunteer group'
          },
          {
            src: Img14,
            caption: 'Profile writing with a Buddy'
          },
          {
            src: Img15,
            caption: 'Lanchester Community Orchard group'
          },
          {
            src: Img16,
            caption: 'Haltwhistle Plant Festival'
          },
          {
            src: Img17,
            caption: 'Profile writing with a Buddy'
          },
        ]
      }
    }

    openLightBox = (item) => {
      console.log(item.url);
      this.setState({lightboxUrl: item.src, lightboxCaption: item.caption,modalOpen: true});
    }

    closeLightBox = () => {
      this.setState({modalOpen: false});
    }

    setModalOpen = (tog) => {
      this.setState({modalOpen: tog})
    }


    render() {
      document.title = 'skillsmatch | About';
      // Conditional rendering shows the sign-in form if user isn't authed, or not if they are.
      return (
        <div style={{background: '#ffffff'}}>


          <div className='frontpage-banner casestudies-banner' id="for-volunteers">
            <div className="frontpage-banner-overlay">
              <div className="frontpage-banner-text">Case Studies</div>
            </div>
          </div>


          <Container text>
            <Grid>
              <Grid.Row>
                <Grid.Column>

                  <br/><br/>


                  Central to the <i>skills</i>match project are opportunities for refugees and local
                  people to mix, spend time together, work alongside each other, learn from each other
                  and build connections in their local communities.
                  <br/><br/>

                  <Grid columns={2}>
                    <Grid.Column key={0}>
                      <Button
                        className='lightbox-clickable'
                        onClick={event => {event.preventDefault(); this.openLightBox(this.state.bfd1)}}
                      >
                        <Image src={this.state.bfd1.src}/> <br/>
                        {this.state.bfd1.caption}
                      </Button>
                    </Grid.Column>
                    <Grid.Column key={1}>
                      <Button
                        className='lightbox-clickable'
                        onClick={event => {event.preventDefault(); this.openLightBox(this.state.bfd2)}}
                      >
                        <Image src={this.state.bfd2.src}/> <br/>
                        {this.state.bfd1.caption}
                      </Button>
                    </Grid.Column>
                  </Grid>


                  <br/><br/>

                  <h2>Group Volunteering Trips</h2>


                  We worked on several supported volunteering trips and events where a group of volunteers
                  helped deliver an activity or the running of an event, alongside local volunteers and staff at
                  that organisation. During the year, we worked with Haltwhistle Plant Festival, Minsteracres
                  Retreat, Lanchester Community Orchard, Baltic Family Days, D6 International Migrants Day.

                  <br/><br/>

                  Volunteers' feedback included:

                  <Segment padded>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={2}>
                          <Icon name='quote left' size='huge' disabled/>
                        </Grid.Column>

                        <Grid.Column width={14}>
                          "I’m very glad to be here today and meet these local community, thank you very much!"
                          <br/><br/>
                          “Being outside was good, not being at home thinking about my case, worrying, being down”
                          <br/><br/>
                          “I met lovely people, I made friends”
                          <br/><br/>
                          “I enjoyed spending time, being useful and helpful. I learned how to work on stage, working while people were watching.”
                          <br/><br/>
                          “Volunteering today has helped improve my English and I have more understanding of the local people about the building and the family days.”
                          <br/><br/>
                          “I got more knowledge about the community, the people when I’m talking with them.”
                          <br/><br/>
                          “I wanted to thank you both personally for your brilliant contribution and support to our recent
                          Family Day and ask you to pass on my thanks to all the Volunteers from West End Refugee Service.…
                          for helping to make our Family Day such a wonderful success with your energy and support.
                          I’ve never seen our venue so alive with laughter, care and creativity.” <br/>
                          <i>Sarah Munro, BALTIC Director, following BALTIC family days, February 2020.</i>
                          <br/><br/>
                          “I absolutely loved working with Aziza, Abula, Namali, Reza, Sadia, Minyone and Abi. They were incredibly helpful and cheerful and brilliant with visitors and in helping with the activities. We can’t imagine how we ever managed to run a Family Day without them, and we can’t imagine doing it without them in the future.”
                          <br/>
                          <i>Annie Bedford, Communities Producer, BALTIC, October 2019</i>
                          <br/><br/>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>

                  </Segment>

                  <Grid columns='3'>
                    <Grid.Row>
                      {
                        this.state.tripsgallery.map((item, idx) =>
                          (
                            <Grid.Column key={idx}>
                              <Button
                                className='lightbox-clickable'
                                onClick={event => {event.preventDefault(); this.openLightBox(item)}}
                              >
                                <Image src={item.src}/> <br/>
                                {item.caption}
                              </Button>
                            </Grid.Column>
                          )
                        )
                      }


                    </Grid.Row>
                  </Grid>

                  <br/><br/>

                  <h2><i>skills</i>match Placements</h2>

                  <i>skills</i>match also supports asylum seekers and refugees into regular volunteering roles at local organisations, supported by the volunteer buddies who help individuals identify their skills and search and apply for opportunities.
                  <br/><br/>
                  Since the start of the project, individuals have volunteered at: Live Theatre Live Tales, Newcastle Meditation Centre, Tyne and Wear Museums, Scotswood Garden, St Vincent De Paul cafes and offices, Oxfam Finance Offices, and Minsteracres Retreat in roles as finance assistants, receptionists, administrators, illustrators at children’s creative writing workshops, café and kitchen assistants and community gardeners.
                  <br/><br/>
                  Quotes below are answers to the question “What has been the main benefit of taking part in <i>skills</i>match?” asked during face to face evaluations, undertaken three months into placements:

                  <Segment padded>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={2}>
                          <Icon name='quote left' size='huge' disabled/>
                        </Grid.Column>

                        <Grid.Column width={14}>
                          “Helps me to think about things other than my situation, takes my mind off it.”
                          <br/><i>Volunteer receptionist at Newcastle Meditation Centre</i>
                          <br/><br/>

                          “Feeling more confident talking to people, more sociable. Staff are lovely.“
                          <br/><i>Volunteer finance assistant at Oxfam </i>
                          <br/><br/>

                          “Change of environment. It was a good opportunity to learn about working in the UK. People were very nice.“
                          <br/><i>Volunteer administration assistant at St Vincent De Paul  </i>
                          <br/><br/>

                          “Feeling more confident talking to people, more sociable. Staff are lovely.“
                          <br/><i>Volunteer finance assistant at Oxfam   </i>
                          <br/><br/>

                          “For my health, to keep me active and busy. I’m happy to have contact with the environment and with people. I’m happy to be sharing my skills. It is also an opportunity to learn new skills.”
                          <br/><i>Community Gardener at Minsteracres Retreat</i>
                          <br/><br/>


                        </Grid.Column>
                      </Grid.Row>
                    </Grid>

                  </Segment>

                  <br/><br/>

                  <h3>Evaluations showed that through volunteering with <i>skills</i>match:</h3>
                  <List className='casestudies-bullets'>
                    <List.Item><Icon name='caret right'/>
                      90% gained more contact with local people
                    </List.Item>
                    <List.Item><Icon name='caret right'/>
                      90% felt they had gained valuable work experience skills
                    </List.Item>
                    <List.Item><Icon name='caret right'/>
                      80% gained more knowledge and understanding of the local community
                    </List.Item>
                    <List.Item><Icon name='caret right'/>
                      80% felt less isolated
                    </List.Item>
                    <List.Item><Icon name='caret right'/>
                      100% felt English language skills have improved
                    </List.Item>
                    <List.Item><Icon name='caret right'/>
                      80% gained confidence in a new role/new setting
                    </List.Item>
                    <List.Item><Icon name='caret right'/>
                      100% feel more confident about applying for other volunteering roles
                    </List.Item>
                  </List>

                  <br/><br/>

                  <Grid columns={2}>
                    <Grid.Column key={0}>
                      <Button
                        className='lightbox-clickable'
                        onClick={event => {event.preventDefault(); this.openLightBox(this.state.img8)}}
                      >
                        <Image src={Img8}/> <br/>
                        {this.state.img8.caption}
                      </Button>
                    </Grid.Column>
                    <Grid.Column key={1}>
                      <Button
                        className='lightbox-clickable'
                        onClick={event => {event.preventDefault(); this.openLightBox(this.state.img9)}}
                      >
                        <Image src={Img9}/> <br/>
                        {this.state.img9.caption}
                      </Button>
                    </Grid.Column>
                  </Grid>

                  <br/><br/>

                  Partner feedback:

                  <Segment padded>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={2}>
                          <Icon name='quote left' size='huge' disabled/>
                        </Grid.Column>

                        <Grid.Column width={14}>
                          “It has been great to welcome a new, diverse group of people in to our team to work alongside our existing volunteer team. It is great that our volunteer team better reflects the diverse community that surrounds us. Bringing together people from different communities and cultures has benefited us all.”
                          <br/><i>Sean Clay, Garden Officer, Scotswood Garden</i>
                          <br/><br/>

                        </Grid.Column>
                      </Grid.Row>
                    </Grid>

                  </Segment>

                  <br/><br/>

                  <Grid columns={1}>
                    <Grid.Column key={0}>
                      <Button
                        className='lightbox-clickable'
                        onClick={event => {event.preventDefault(); this.openLightBox(this.state.img10)}}
                      >
                        <Image src={Img10}/> <br/>
                        {this.state.img10.caption}
                      </Button>
                    </Grid.Column>
                  </Grid>

                  <br/><br/>

                  <h2>Volunteer Placement Case Study</h2>

                  <br/>
                  <Grid columns={1}>
                    <Grid.Column key={0}>
                      <Button
                        className='lightbox-clickable'
                        onClick={event => {event.preventDefault(); this.openLightBox(this.state.img11)}}
                      >
                        <Image src={Img11}/> <br/>
                        {this.state.img11.caption}
                      </Button>
                    </Grid.Column>
                  </Grid>



                  <br/><br/>

                  Sadia first registered with <i>skills</i>match in November 2018. She had practised as an artist in her home country and was keen to find a creative role here in Newcastle where she could apply her skills and also keep busy whilst awaiting news of her asylum claim. She applied in February 2019 to volunteer at Live Theatre’s Live Tales project which hosts creative writing sessions for school children. She was interested in the role of illustrator, started there in March 2019 and continued until November 2019.
                  <br/><br/>
                  In her evaluation, Sadia said of her volunteer placement:

                  <Segment padded>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={2}>
                          <Icon name='quote left' size='huge' disabled/>
                        </Grid.Column>

                        <Grid.Column width={14}>
                          “I came to know about how to do quick illustration, I have never done this before, so obviously I’ve learnt a lot from them.”
                          <br/><br/>

                          “Before beginning of the sessions, I write name tags for the students, arrange my table to illustrate. The other person, they are writing, students make a pose to tell the story and every student does that pose. I draw that pose/position and the stories are shown on the screen.”
                          <br/><br/>

                          “Every time there is a new thing – new students, new idea, new stories, new strategies. When the students decide a lot of things in their stories, that can become challenging for me.”


                        </Grid.Column>
                      </Grid.Row>
                    </Grid>

                  </Segment>

                  <br/><br/>

                  Izzie Hutchinson, Live Tales, Volunteer Coordinator gave feedback in her evaluation:

                  <Segment padded>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={2}>
                          <Icon name='quote left' size='huge' disabled/>
                        </Grid.Column>

                        <Grid.Column width={14}>
                          “Through <i>skills</i>match, we have welcomed a wonderful illustrator into our volunteering family. This individual is extremely talented and is a delight to have in our company. They have been willing to give everything a go and are punctual and dedicated.  By having this individual in our team, we have been able to offer schools are unique experience of having their characters been brought to life, something that we may have not been able to do without this individual. It has been good to have them on a weekly basis and be able to rely on them.”
                          <br/><br/>

                          “I believe that <i>skills</i>match is such a valuable programme, it feels approachable and easy to manage and is an amazing opportunity for both its users and clients.”

                        </Grid.Column>
                      </Grid.Row>
                    </Grid>

                  </Segment>

                  <br/><br/>

                  Since Sadia finished her placement, she has volunteered through <i>skills</i>match at Arts Organisation’s D6 International Migrant’s Day Celebration in December 2020, helping to set up and decorate the event venue and at BALTIC Family Days in 2020, where she supported artists with creative activities. Sadia has also been volunteering for WERS since January 2020, taking photographs of the Volunteer project.  She was also in discussion with D6, following her volunteering with them, and prior to Covid 19 about taking up an artists’ residency opportunity as part of D6’s Studio of Sanctuary Project. We hope this is something that can be pursued in the future.

                  <br/><br/>

                  <Divider />

                  <h3>Gallery</h3>

                  <Grid columns='3'>
                    <Grid.Row>
                      {
                        this.state.miscgallery.map((item, idx) =>
                          (
                            <Grid.Column key={idx}>
                              <Button
                                className='lightbox-clickable'
                                onClick={event => {event.preventDefault(); this.openLightBox(item)}}
                              >
                                <Image src={item.src}/> <br/>
                                {item.caption}
                              </Button>
                            </Grid.Column>
                          )
                        )
                      }


                    </Grid.Row>
                  </Grid>


                  <br/><br/><br/>

                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>

          <Modal
            onClose={() => this.setModalOpen(false)}
            onOpen={() => this.setModalOpen(true)}
            open={this.state.modalOpen}
            closeIcon
            >
            <Modal.Content image>
              <Button className='lightbox-closer'
              onClick={event => {event.preventDefault(); this.closeLightBox()}}
              >
              <Image fluid src={this.state.lightboxUrl}/>
              </Button>
            </Modal.Content>
            <Modal.Description align='center'>
              <h3>{this.state.lightboxCaption}</h3>
            </Modal.Description>
          </Modal>

        </div>

      )

    }
}
