import React, { Component } from 'react';
import { Container, Grid} from 'semantic-ui-react';
//import CafeLadies from '../res/skillsmatch_cafe_ladies.jpg';


export default class AboutPage extends Component {



    render() {
      document.title = 'skillsmatch | About';
      // Conditional rendering shows the sign-in form if user isn't authed, or not if they are.
      return (
        <div style={{background: '#ffffff'}}>



          <Container text>
            <h1 centered>About Cookies</h1>
            <Grid>
              <Grid.Row>
                <Grid.Column>


                  <h2>Visitors to this website</h2>

                  We do <strong>not</strong> collect any data, personal or anonymised, about visitors to
                  skills<i>match</i>. Also we don't install any of our own cookies on your computer for any
                  purposes, such as tracking, marketing or preferences.

                  <h2 >Registered users</h2>

                  <p>
                    If you're a registered Volunteer, or a Partner organisation, you have agreed to our
                    terms and conditions under which we securely store only the personal data that you
                    actively share with us in order to use skills<i>match</i> for its intended purpose.
                    <br/><br/>
                    Some of this information is stored in your browser's LocalStorage, so that you don't
                    have to sign in each time you visit the site, but you can clear this whenever you wish
                    by signing out
                    of skills<i>match</i> using the 'SIGN OUT' button. You should always do this if you're
                    using a public or shared computer, although when using a public or shared computer it's
                    best to use 'incognito' or 'private browsing' modes, which delete all Local Storage data when
                    you close the window.
                    <br/><br/>
                    Even when you're signed in, we don't track location, or any of your other browsing activities,
                    or gather any data for marketing purposes.
                  </p>

                  <h2 >3rd Party Cookies</h2>
                  <p>
                    Full disclosure: when browsing Opportunities as a registered and signed-in user, we display
                    a Google Map of that Opportunity's location, so volunteers can see where it is
                    and figure out whether they can travel there. Google Maps stores a cookie of its own
                    on your computer, so when you click 'Allow all cookies', that's the 3rd party cookie
                    we're referring to! Also, there's one more cookie that stores your answer to the 'Allow
                    all cookies' question so that you're not asked every time you visit.

                  </p>

                  <h2>Summary</h2>
                  <p>
                    We hope that puts your mind at ease. The West End Refugee Service is a registered
                    charity (no. 1077601) and skills<i>match</i> has one specific purpose: that of connecting
                    volunteers with volunteering opportunities. We have no interest in marketing data
                    or in tracking your browsing activities. At the same time, we want to comply with GDPR
                    (General Data Protection Regulation) by being absolutely clear about where and how we
                    use cookies or local storage. Thanks for reading!
                  </p>



                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>







        </div>

      )

    }
}
